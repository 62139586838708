<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card>
          <v-data-table
            :headers="headers"
            class="elevation-1"
            :items="itemsPlantillaCargaTramites"
            :loading="loading"
            :search="search"
          >
            <template v-slot:[`item.habilitaEnApp`]="{ item }">
              <v-icon
                v-if="item.habilitaEnApp === true"
                small
                color="primary"
                class="d-flex justify-center"
              >
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:[`item.havePlantilla`]="{ item }">
              <v-icon
                v-if="item.havePlantilla"
                small
                color="primary"
                class="d-flex justify-center"
              >
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:[`item.haveArchivos`]="{ item }">
              <v-icon
                v-if="item.adjuntosCant > 0"
                small
                color="primary"
                class="d-flex justify-center"
              >
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canConfig">
                <template v-slot:activator="{ on, attrs }">
                  <section style="display: inline" v-on="on">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="openModalConfig(item)"
                    >
                      {{ settingsIcon }}
                    </v-icon>
                  </section>
                </template>
                <span>Configuración de aplicación móvil</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions, mapGetters } from "vuex";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "PlantillaCargaTramites",
  components: {
    PageHeader,
    GoBackBtn,
    Ayuda
  },
  data() {
    return {
      headers: [
        {
          text: "Tipo de trámite",
          value: "mTipoExpNombre",
          align: "start",
          sortable: false
        },
        {
          text: "Habilitado en app",
          value: "habilitaEnApp",
          align: "center",
          sortable: false
        },
        {
          text: "Tiene plantilla",
          value: "havePlantilla",
          align: "center",
          sortable: false
        },
        {
          text: "Archivos adjuntos",
          value: "haveArchivos",
          align: "center",
          sortable: false
        },
        { text: "Acciones", value: "actions", align: "end", sortable: false }
      ],
      title: enums.titles.TITLE_PLANTILLA_CARGA_TRAMITES,
      checkIcon: enums.icons.CHECK_OUTLINE,
      optionCode: enums.webSiteOptions.PLANTILLA_CARGA_TRAMITES,
      settingsIcon: enums.icons.SETTINGS,
      showHelp: false,
      showExpand: false,
      showIcon: true,
      loading: false,
      itemsPlantillaCargaTramites: [],
      routeToGo: "ConfiguracionAppBenef",
      allowedActions: null,
      search: "",
      searchIcon: enums.icons.SEARCH,
      canConfig: false,
      idTramite: null
    };
  },
  computed: {
    ...mapGetters({
      listModulos: "user/modulos"
    })
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loading = false;
    this.getTramites();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      traerTiposExpedientes: "configuracionME/traerTiposExpedientes"
    }),
    openModalConfig(item) {
      this.$router.push({
        name: "ConfigCargaTramites",
        params: {
          idTramite: item
        }
      });
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .CONFIG_PLANTILLA_CARGA_TRAMITES:
            this.canConfig = true;
          default:
            break;
        }
      });
    },
    async getTramites() {
      const response = await this.traerTiposExpedientes();
      this.itemsPlantillaCargaTramites = response;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
